<template>
  <v-container fluid>
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
          | <span class="title">Summary</span>
        </h1>
      </v-col>
    </v-row>

    <v-container>
      <v-row>
        <v-col class="mx-auto" cols="12" md="12">
          <v-card>
            <div class="tw-flex tw-justify-center tw-items-center tw-p-6">
              <div class="tw-w-full md:tw-w-1/2">
                <app-welcome-wordings></app-welcome-wordings>
              </div>

              <div class="tw-relative tw-w-80 tw-hidden md:tw-block">
                <img
                  src="@/assets/img/Group.png"
                  alt="Group"
                  class="tw-absolute tw-top-20 tw-left-10"
                />

                <img
                  src="@/assets/img/Face.png"
                  alt="Face"
                  class="tw-mx-auto"
                />
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-8">
      <v-row>
        <v-col class="mx-auto" cols="12" md="12">
          <v-card>
            <div class="tw-px-2 md:tw-px-6">
              <card-title icon="mdi-clipboard-text-outline" :is-image="false"
                >Assessment Centre Updates</card-title
              >
            </div>

            <div class="tw-px-2 md:tw-px-6 tw-py-10">
              <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-8">
                <v-card
                  class="tw-px-2 md:tw-px-6 !tw-overflow-visible"
                  border
                  elevation="2"
                  outlined
                  v-for="(item, index) in summaries"
                  :key="index"
                >
                  <div class="tw-flex tw-justify-between">
                    <div
                      class="
                        tw--mt-3
                        tw-w-16
                        tw-h-16
                        tw-flex
                        tw-justify-center
                        tw-items-center
                        tw-rounded
                        c-bg-blue
                      "
                    >
                      <v-icon color="white" dense>{{ item.icon }}</v-icon>
                    </div>

                    <div
                      class="tw-flex tw-flex-col tw-items-end tw-gap-3 tw-pt-2"
                    >
                      <p class="c-gray-text">{{ item.title }}</p>

                      <v-skeleton-loader
                        v-if="isLoading"
                        type="text"
                        height="20px"
                        width="60px"
                        class="!tw-m-0"
                      >
                      </v-skeleton-loader>
                      <p v-else class="tw-text-xl">{{ item.count }}</p>
                    </div>
                  </div>

                  <div class="tw-mt-10">
                    <v-divider class="border-opacity-75"></v-divider>

                    <div class="tw-flex tw-items-center tw-gap-1 tw-p-2">
                      <v-icon class="c-gray-text">mdi-clock-time-four</v-icon>

                      <p class="c-gray-text tw-text-sm mb-0">Just Updated</p>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'

export default {
  components: {
    CardTitle,
    appWelcomeWordings: () => import('@/components/dashboard/Welcome'),
  },
  props: {
    summary: {
      type: Object,
      required: true,
      default: () => ({
        bidForms: 0,
        reinspections: 0,
        supplementaries: 0,
      }),
    },
    isLoading: { type: Boolean, required: true },
  },
  computed: {
    summaries() {
      return [
        {
          title: 'Bid Templates Received',
          icon: 'mdi-file-document',
          count: this.summary.bidForms,
        },
        {
          title: 'No. of Supplementaries',
          icon: 'mdi-file-document-plus',
          count: this.summary.supplementaries,
        },
        {
          title: 'No. of Reinspections',
          icon: 'mdi-car-search',
          count: this.summary.reinspections,
        },
      ]
    },
  },
}
</script>
